import React from 'react';
import { reduxForm, Field } from 'redux-form';
import styled, { css } from 'styled-components';
import { compose } from 'ramda';
import PropTypes from 'prop-types';
import RoundedButton from '../buttons/roundedButton';
import {
  LAPTOP, MOBILE, TABLET, media,
} from '../../constants/screens';
import withNetlifySubmission from '../../enhancers/withNetlifySubmission';
import Interests from './interests';
import { validate } from '../../utils/validate';
import Placeholder from '../placeholder/index';
import Recaptcha from '../common/recaptcha';
import {
  BLUE,
  DARK_GRAY,
  DARK_GRAY_2,
  WHITE,
} from '../../constants/colors';
import Alert from '../common/alert';
import { getComponentByType } from '../../utils';
import { getValidationSchema } from '../../utils/form';

const CustomResearchEnquiry = ({
  onSubmit, isSubmitting, handleRecaptcha, handleSubmit, form, alert,
  data: {
    formFields = [], recaptcha, checkboxes, emailMe, requiredLabel, submitLabel, showEmailMe,
  },
}) => {
  const { formFields: checkboxesFields, label, required } = checkboxes;
  const inputFields = formFields.filter(field => field.type !== 'textarea');
  const textAreaField = formFields.find(field => field.type === 'textarea');

  return (
    <FormWrapper>
      <Form
        name="custom-research-enquiry"
        method="post"
        data-netlify="true"
        data-netlify-recaptcha="true"
        onSubmit={handleSubmit(onSubmit)}
      >
        {
          inputFields.map(({
            id, required: r, label: l, htmlName, type, selectOptions,
          }) => (
            <Field
              key={id}
              name={htmlName}
              options={selectOptions}
              component={getComponentByType(type)}
              type={type}
              minHeight={type === 'textarea' ? '330px' : null}
              PlaceholderComponent={
                <Placeholder title={l.md} required={r} />
              }
              errorStyle={ErrorCss}
            />
          ))
        }
        <CheckBoxContainer>
          <CheckBoxContainerTitle>
            {label}
            {required && (<p> *</p>)}
          </CheckBoxContainerTitle>
          <CheckBoxContent>
            <Interests form={form} fields={checkboxesFields} errorStyle={InterestsErrorCss} />
          </CheckBoxContent>
        </CheckBoxContainer>
        {textAreaField && (
          <Field
            key={textAreaField.id}
            required={false}
            name={textAreaField.htmlName}
            component={getComponentByType('textarea')}
            type={textAreaField.type}
            minHeight="180px"
            PlaceholderComponent={
              <Placeholder title={textAreaField.label.md} required={false} />
            }
            errorStyle={ErrorCss}
          />
        )}
        <RequiredText>
          {requiredLabel}
        </RequiredText>
        <EmailMe>
          {emailMe && showEmailMe && (
            <Field
              name={emailMe.htmlName}
              component={getComponentByType(emailMe.type)}
              placeholder="Personal email"
              props={{
                markdownText: emailMe.label.md,
                fontSize: '19px',
                fontFamily: 'AvenirNextLTPro',
              }}
            />
          )}
        </EmailMe>
        {recaptcha
        && (
          <Recaptcha
            verifyCallback={token => handleRecaptcha({ type: 'verify', payload: token })}
            expiredCallback={() => handleRecaptcha({ type: 'expired' })}
          />
        )
        }
        <Button
          text={submitLabel}
          color={BLUE}
          colorWithoutHover={WHITE}
          background={BLUE}
          withoutHover
          type="submit"
          disabled={isSubmitting}
        />
        <AlertContainer>
          <Alert type={alert.type} message={alert.message} />
        </AlertContainer>
        <input type="hidden" name="form-name" value="custom-research-enquiry" />
      </Form>
    </FormWrapper>
  );
};

CustomResearchEnquiry.propTypes = {
  onSubmit: PropTypes.func,
  alert: PropTypes.shape(),
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleRecaptcha: PropTypes.func,
  form: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
};

CustomResearchEnquiry.defaultProps = {
  onSubmit: () => null,
  alert: {},
  handleRecaptcha: () => null,
};

const ErrorCss = css`
  margin: -10px 0 10px 0;
`;

const InterestsErrorCss = css`
  margin-top: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 44.5px 5.5px rgba(220, 227, 238, 0.6);
  border-radius: 10px;
  padding: 80px 70px;
  
  ${media.laptop`
    padding: 60px 50px;
  `}
  
  @media screen and (max-width: ${TABLET}) {
    padding: 5%; 
      
    > div:last-child {
      align-self: center;
    }
    
    button {
      width: 260px;
    }
  }
`;

const FormWrapper = styled.div`
  flex: 0.55;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  margin: 25px 0 60px 0;
  
  @media screen and (max-width: ${LAPTOP}) {
    margin: 15px 0 40px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    margin: 25px 0;
  }
`;

const CheckBoxContainerTitle = styled.div`
  font-family: AvenirNextLTProBold;
  font-size: 21px;
  line-height: normal;
  color: ${DARK_GRAY};
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  
  > p {
    margin-left: 5px;
    color: #ff0000;
  }
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 18px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    text-align: center;
    text-transform: uppercase;
    font-family: AvenirNextLTPro;
    align-items: center;
    justify-content: center;
  }
`;

const CheckBoxContent = styled.div`
  display: flex;
`;

const RequiredText = styled.p`
  font-family: AvenirNextLTPro;
  font-size: 19px;
  line-height: normal;
  letter-spacing: normal;
  color: ${DARK_GRAY_2};
  padding-left: 15px;
  margin-bottom: 20px;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 17px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 17px;
  }
`;

const EmailMe = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  
  @media screen and (max-width: ${LAPTOP}) {
    margin-bottom: 40px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    margin-bottom: 20px;
  }
`;

const AlertContainer = styled.div`
  margin-top: 20px;
`;

const Button = styled(RoundedButton)`
  @media screen and (max-width: ${MOBILE}) {
    margin: auto;
  }
`;

export default compose(
  reduxForm({
    form: 'custom-research-enquiry',
    validate: (values, { data }) => compose(
      validate({
        ...getValidationSchema(data),
        describeYourCustomResearchProject: undefined,
      }),
    )(values),
  }),
  withNetlifySubmission(),
)(CustomResearchEnquiry);
