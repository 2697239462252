import React from 'react';
import { connect } from 'react-redux';
import * as form from 'redux-form';
import PropTypes from 'prop-types';
import { getError } from '../redux/selectors/form';
import Error from '../components/common/error';

export default function () {
  return function WithReduxFormErrorHOC(WrappedComponent) {
    class WithReduxFormError extends React.Component {
      constructor() {
        super();
        this.state = { touched: false };
      }

      componentDidUpdate = (prevProps) => {
        const { error, hasSubmitSucceeded } = this.props;
        const { touched } = this.state;
        if (prevProps.error && !error && !touched) {
          this.setState({ touched: true });
        }

        if (hasSubmitSucceeded && hasSubmitSucceeded !== prevProps.hasSubmitSucceeded) {
          this.setState({ touched: false });
        }
      }

      render() {
        const { error, errorStyle, hasSubmitFailed } = this.props;
        const { touched } = this.state;

        return (
          <div>
            <WrappedComponent {...this.props} />
            {error && (touched || hasSubmitFailed)
              ? <Error styles={errorStyle}>{error}</Error>
              : null
            }
          </div>
        );
      }
    }

    WithReduxFormError.propTypes = {
      error: PropTypes.arrayOf(PropTypes.string),
      errorStyle: PropTypes.arrayOf(PropTypes.string),
      hasSubmitFailed: PropTypes.bool,
      hasSubmitSucceeded: PropTypes.bool,
    };

    WithReduxFormError.defaultProps = {
      error: null,
      errorStyle: null,
      hasSubmitFailed: false,
      hasSubmitSucceeded: false,
    };

    let errorSelector = null;
    let hasSubmitFailedSelector = null;
    let hasSubmitSucceededSelector = null;

    const mapStateToProps = (state, props) => {
      errorSelector = errorSelector || getError(props.form, props.field);
      hasSubmitFailedSelector = hasSubmitFailedSelector
        || form.hasSubmitFailed(props.form);
      hasSubmitSucceededSelector = hasSubmitSucceededSelector
        || form.hasSubmitSucceeded(props.form);

      return {
        error: errorSelector(state),
        hasSubmitFailed: hasSubmitFailedSelector(state),
        hasSubmitSucceeded: hasSubmitSucceededSelector(state),
      };
    };

    return connect(mapStateToProps, null)(WithReduxFormError);
  };
}
